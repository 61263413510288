import React from "react";

export const Enroll = (props) => {
  return (
    <div id="enroll">
      <div className="container">
        <div className="row">
            <div className="section-title text-center">
                <h2>Get into the best shape of your life</h2>
                <br></br>
                <a
                  href="#contact"
                  className="btn btn-custom2 btn-lg page-scroll"
                >
                  Enroll Now
                </a>{" "}
            </div>
        </div>
      </div>
    </div>
  );
};
