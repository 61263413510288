export const Modal = ({ show, onClose }) => {
    if (!show) {
      return null;
    }
    
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Success!</h2>
          <p>Your request was successfully submited!</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };