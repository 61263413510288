import React from "react";

export const Terms = (props) => {
  const termsData = [
    {
      title: "Medical Disclaimer",
      description:
        "Any information provided from our end is not medical advice. We strongly recommend you seek medical advice before starting a workout or diet program.",
    },
    {
      title: "Scope of Services",
      description:
        "Our coaching services focus solely on providing guidance and support for achieving fitness goals through fitness training and nutrition planning. We are not licensed therapists or psychologists. We do not provide any form of psychological therapy or counselling.",
    },
    {
      title: "Communication Protocol",
      description:
        "Daily communication has to be initiated from your end over WhatsApp chat. Calls are not part of the coaching program. Communication is via text/voice note.",
    },
    {
      title: "Daily Updates Requirement",
      description:
        "You are advised to share daily meal and workout updates via pictures and videos to enhance and optimize the training experience. Failing to do so could affect the coaching experience.",
    },
  ];

  return (
    <div id="terms" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>T & C</h2>
          <p>
            Please read the terms and conditions carefully before proceeding
          </p>
        </div>
        <div className="">
          <ul className="themed-list">
            {termsData.map((term, index) => (
              <li key={index}>
                <h3>{term.title}</h3>
                <p>{term.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="">
        <div className="social">
          <ul>
            <li>
              <a href={props.data ? props.data.facebook : "/"}>
                <i className="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href={props.data ? props.data.twitter : "/"}>
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
            <li>
              <a href={props.data ? props.data.youtube : "/"}>
                <i className="fa fa-youtube"></i>
              </a>
            </li>
          </ul>
          <div className="container text-center">
            <p>
              &copy; 2024 Shop Anand Vardhan - All Rights Reserved. Design by{" "}
              <a
                href="https://www.linkedin.com/in/aniket-rajpoot/"
                rel="nofollow"
              >
                Aniket Singh Rajpoot
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
