import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row align-bottom"> {/* Added align-bottom class */}
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {/* {props.data ? props.data.title : "Loading"} */}
                  <span>1-ON-1</span>
                </h1>
                <h1>
                  {props.data ? props.data.subtitle : "Loading"}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
